import React, { useState, useEffect, useCallback } from "react";
import useTableHeight from "../../Services/TableHeight";
import { Table, Button, Popover, Checkbox, Input, Select, Modal, } from "antd";
import { SearchOutlined, TableOutlined } from "@ant-design/icons";
import "./GoogleAds.scss";
import { getApi, postApi } from "../../Utils/apiService";
import { GET_ALL_WEBHOOKEVENTS, ICP_DROPDOWN } from "../../Utils/apiPath";
import { useUserContext } from "../../Services/UserContext";
import {
  formatTimeDifference,
  formatTimeDifferenceUTC,
  ResizableTitle,
} from "../../Services/HelperFunctions";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import Buttons from "../../Components/Buttons/Buttons";
import {
  department_data,
  employee_data,
  industry_data,
  location_data,
  revenue_data,
  level_data,
} from "../../Services/icp_attributes_maste";
import { CSVLink } from "react-csv";

const GoogleAds = () => {
  const navigate = useNavigate();
  const { profileData } = useUserContext();
  const [tableData, setTableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalCount, setTotalCount] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [icpDropdownData, setIcpDropdownData] = useState([]);
  const [selectedIcpId, setSelectedIcpId] = useState(0);
  const [selectedIcpDetails, setSelectedIcpDetails] = useState(null);
  const [icpData, setIcpData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [pageIndexModal, setPageIndexModal] = useState(1);
  const [totalPagesModal, setTotalPagesModal] = useState(1);
  const [visibleColumns, setVisibleColumns] = useState([
    "companyDomain",
    "companyEmployeeRange",
    "companyIndustry",
    "companyAnnualRevenueRange",
    "userFirstChannel",
    "userLatestSearchTerm",
    "userLatestReferrerDomain",
    "userLatestCampaign",
    "userFirstTerm",
    "createdDate",
  ]);

  const [columns, setColumns] = useState([
    {
      title: "Company Domain",
      dataIndex: "companyDomain",
      width: 250,
      fixed: "left",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {text && (
            <img
              src={record.companyDomainUrl}
              onError={(e) =>
                (e.target.src =
                  "https://upload.wikimedia.org/wikipedia/commons/c/ce/Example_image.png")
              }
              alt={`${text} logo`}
              style={{ width: "30px", height: "25px", marginRight: "8px" }}
            />
          )}
          <span
            style={{
              color: "black",
              cursor: "pointer",
            }}
            onClick={() => handleDomainClick(record)}
            onMouseEnter={(e) => {
              e.target.style.color = "blue";
              e.target.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "black";
              e.target.style.textDecoration = "none";
            }}
          >
            {text || "-"}
          </span>
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: 200,
      render: (companyName) => (companyName ? companyName : "-"),
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyName"),
      }),
    },
    {
      title: "Company Employee Range",
      dataIndex: "companyEmployeeRange",
      width: 150,
      render: (companyEmployeeRange) =>
        companyEmployeeRange ? companyEmployeeRange : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyEmployeeRange"),
      }),
    },
    {
      title: "Company Industry",
      dataIndex: "companyIndustry",
      width: 200,
      render: (companyIndustry) => (companyIndustry ? companyIndustry : "-"),
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyIndustry"),
      }),
    },
    {
      title: "Company Annual Revenue Range",
      dataIndex: "companyAnnualRevenueRange",
      width: 200,
      render: (companyAnnualRevenueRange) =>
        companyAnnualRevenueRange ? companyAnnualRevenueRange : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyAnnualRevenueRange"),
      }),
    },
    {
      title: "Company Annual Revenue",
      dataIndex: "companyAnnualRevenue",
      width: 200,
      render: (companyAnnualRevenue) =>
        companyAnnualRevenue ? companyAnnualRevenue : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyAnnualRevenue"),
      }),
    },
    {
      title: "Account First Channel",
      dataIndex: "userFirstChannel",
      width: 200,
      render: (userFirstChannel) => (userFirstChannel ? userFirstChannel : "-"),
    },
    {
      title: "Account Latest Term",
      dataIndex: "userLatestSearchTerm",
      width: 200,
      render: (userLatestTerm) => (userLatestTerm ? userLatestTerm : "-"),
    },
    {
      title: "Account Latest Referrer Domain",
      dataIndex: "userLatestReferrerDomain",
      width: 200,
      render: (userLatestReferrerDomain) =>
        userLatestReferrerDomain ? userLatestReferrerDomain : "-",
    },
    {
      title: "Account Latest Campaign",
      dataIndex: "userLatestCampaign",
      width: 200,
      render: (userLatestCampaign) =>
        userLatestCampaign ? userLatestCampaign : "-",
    },
    {
      title: "Account First Term",
      dataIndex: "userFirstTerm",
      width: 200,
      render: (userFirstTerm) => (userFirstTerm ? userFirstTerm : "-"),
    },
    {
      title: "Company Type",
      dataIndex: "companyType",
      width: 150,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("companyType"),
      }),
    },
    {
      title: "Company ID",
      dataIndex: "companyID",
      width: 150,
    },
    {
      title: "Company Description",
      dataIndex: "companyDescription",
      width: 300,
    },
    {
      title: "Country ISO Code",
      dataIndex: "companyCountryISOCode",
      width: 150,
    },
    {
      title: "Traffic Rank",
      dataIndex: "companyTrafficRank",
      width: 150,
    },
    {
      title: "State",
      dataIndex: "companyState",
      width: 150,
    },
    {
      title: "Legal Name",
      dataIndex: "companyLegalName",
      width: 150,
    },
    {
      title: "Employee Count",
      dataIndex: "companyEmployeeCount",
      width: 150,
    },
    {
      title: "Funding Raised",
      dataIndex: "companyFundingRaised",
      width: 150,
    },
    {
      title: "Market Cap",
      dataIndex: "companyMarketCap",
      width: 150,
    },
    {
      title: "SIC Code",
      dataIndex: "companySICCode",
      width: 150,
    },
    {
      title: "Industry Group",
      dataIndex: "companyIndustryGroup",
      width: 150,
    },
    {
      title: "Sub Industry",
      dataIndex: "companySubIndustry",
      width: 150,
    },
    {
      title: "NAICS Code",
      dataIndex: "companyNAICSCode",
      width: 150,
    },
    {
      title: "Sector",
      dataIndex: "companySector",
      width: 150,
    },
    {
      title: "User Country",
      dataIndex: "userCountry",
      width: 150,
    },
    {
      title: "User Continent",
      dataIndex: "userContinent",
      width: 150,
    },
    {
      title: "User City",
      dataIndex: "userCity",
      width: 150,
    },
    {
      title: "User Postal Code",
      dataIndex: "userPostalCode",
      width: 150,
    },
    {
      title: "User State",
      dataIndex: "userState",
      width: 150,
    },
    {
      title: "User Email ID",
      dataIndex: "userEmailID",
      width: 150,
    },
    {
      title: "User ID",
      dataIndex: "userID",
      width: 150,
    },
    {
      title: "First Seen Hour",
      dataIndex: "firstSeenHour",
      width: 150,
    },
    // {
    //   title: "First Seen Day",
    //   dataIndex: "firstSeenDay",
    //   width: 150,
    // },
    {
      title: "Last Activity",
      dataIndex: "createdDate",
      width: 150,
      render: (createdDate) =>
        createdDate ? formatTimeDifferenceUTC(createdDate) : "-",
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize("createdDate"),
      }),
    },
  ]);

  const tableHeight = useTableHeight();

  const handleDomainClick = (record) => {
    navigate("/google-ads-details", { state: { data: record } });
  };

  const handleResize =
    (dataIndex) =>
    (e, { size }) => {
      setColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        const index = nextColumns.findIndex(
          (col) => col.dataIndex === dataIndex
        );
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const fetchWebHookEvents = useCallback(async () => {
    setIsLoading(true);
    const payload = {
      pageIndex: pageIndex - 1,
      pageSize,
      searchString,
      projectName: profileData.paidSearchProjectName,
      icpId: selectedIcpId,
    };

    const { statusCode, data, totalCount } = await postApi(
      GET_ALL_WEBHOOKEVENTS,
      payload
    );

    if (statusCode === 200) {
      setTableData(data);
      setTotalCount(totalCount);
      setIsLoading(false);
    } else {
      console.error("Error: data is not in the expected format.");
      setIsLoading(false);
    }
  }, [profileData, pageIndex, pageSize, searchString, selectedIcpId]);

  const debouncedFetch = useCallback(debounce(fetchWebHookEvents, 500), [
    fetchWebHookEvents,
  ]);

  useEffect(() => {
    if (profileData?.ProjectName) {
      debouncedFetch();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [profileData, pageIndex, pageSize, searchString, debouncedFetch]);

  const handleColumnVisibilityChange = (columnKey) => {
    setVisibleColumns((prev) =>
      prev.includes(columnKey)
        ? prev.filter((key) => key !== columnKey)
        : [...prev, columnKey]
    );
  };

  const getPageSizeOptions = () => {
    if (totalCount <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["10", "20", "50", "100"];
    }
  };

  const filteredColumns = columns.filter((col) =>
    visibleColumns.includes(col.dataIndex)
  );

  const fetchICPDropdown = async () => {
    const { statusCode, data, message } = await getApi(ICP_DROPDOWN);
    if (statusCode === 200) {
      const formattedData = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setIcpDropdownData(formattedData);
      setIcpData(data);
    } else {
      console.error("Error: data is not in the expected format.");
    }
  };

  useEffect(() => {
    fetchICPDropdown();
  }, []);

  const popoverContent = (
    <div
      style={{
        width: "200px",
        maxHeight: "250px",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {columns.map((col) => (
        <Checkbox
          key={col.dataIndex}
          checked={visibleColumns.includes(col.dataIndex)}
          onChange={() => handleColumnVisibilityChange(col.dataIndex)}
        >
          {col.title}
        </Checkbox>
      ))}
    </div>
  );

  const handleEnrich = () => {
    setIsLoading(true);
    const enrichedData = tableData?.map((item) => ({
      companyName: item?.companyName,
    }));

    const requestPayload = {
      SearchOption: [
        {
          ParameterName: "APIKEY",
          ParameterValue: "5206ad59-50a1-4f43-a4a0-c068afb6702b",
        },
        {
          ParameterName: "PAGEINDEX",
          ParameterValue: pageIndexModal?.toString(),
        },
      ],
      Condition: [],
    };

    const mapDataToIdText = (data, value) => {
      const found = data?.find((item) => item?.name === value);
      return found ? { ID: found?.id, Text: found?.name } : null;
    };

    const aggregatedConditions = {};

    enrichedData?.forEach((item) => {
      const conditions = [
        {
          column: "IndustryID",
          value: mapDataToIdText(industry_data, selectedIcpDetails?.industry[0]),
        },
        {
          column: "CompanyID",
          value: item.companyName
            ? { ID: item.companyName, Text: item?.companyName }
            : null,
        },
        {
          column: "CountryID",
          value: mapDataToIdText(location_data, selectedIcpDetails?.location[0]),
        },
        {
          column: "Employee_RangeID",
          value: mapDataToIdText(
            employee_data,
            selectedIcpDetails?.employees[0]
          ),
        },
        {
          column: "Revenue_RangeID",
          value: mapDataToIdText(revenue_data, selectedIcpDetails?.revenue[0]),
        },
        {
          column: "DepartmentID",
          value: mapDataToIdText(
            department_data,
            selectedIcpDetails?.department[0]
          ),
        },
        {
          column: "LevelID",
          value: mapDataToIdText(level_data, selectedIcpDetails?.level[0]),
        },
      ];

      conditions.forEach(({ column, value }) => {
        if (value) {
          if (
            column === "CountryID" ||
            column === "IndustryID" ||
            column === "Employee_RangeID" ||
            column === "Revenue_RangeID" ||
            column === "DepartmentID" ||
            column === "LevelID"
          ) {
            if (!aggregatedConditions[column]) {
              aggregatedConditions[column] = [];
            }
            if (!aggregatedConditions[column].some((v) => v.ID === value.ID)) {
              aggregatedConditions[column].push(value);
            }
          } else {
            if (!aggregatedConditions[column]) {
              aggregatedConditions[column] = [];
            }
            aggregatedConditions[column].push(value);
          }
        }
      });
    });

    requestPayload.Condition = Object.entries(aggregatedConditions).map(
      ([key, values]) => ({
        SearchColumn: key,
        SearchValue: values,
      })
    );

    fetch(
      "https://enrichlead.lakeb2b.com/v1/searchview?APIKEY=5206ad59-50a1-4f43-a4a0-c068afb6702b",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestPayload),
      }
    )
      .then((response) => response?.json())
      .then((data) => {
        setIsLoading(false);
        if (data) {
          setApiResponseData(data?.DataRows);
          setTotalPagesModal(data?.TotalPage);
          setIsModalVisible(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("API Error:", error);
      });
  };

  const handlePageChangeModal = (newPageIndex) => {
    setPageIndexModal(newPageIndex);
    handleEnrich();
  };

  const columns2 = [
    {
      title: "Full Name",
      dataIndex: "FullName",
      key: "FullName",
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
    },
    {
      title: "Email Address",
      dataIndex: "EmailAddress",
      key: "EmailAddress",
    },
    {
      title: "Company Name",
      dataIndex: "CompanyName",
      key: "CompanyName",
    },
    {
      title: "Company Phone",
      dataIndex: "CompanyPhone",
      key: "CompanyPhone",
    },
    {
      title: "Web Address",
      dataIndex: "WebAddress",
      key: "WebAddress",
    },
    {
      title: "LinkedIn URL",
      dataIndex: "LinkedInURL",
      key: "LinkedInURL",
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <div className="ads">
        <div className="ads_top">
          <div className="ads_top_left">
            <h2>Google Ads</h2>
          </div>
          <div className="ads_top_right">
          <Buttons
              style={{
                backgroundColor: "#28a745",
                display: selectedIcpId && tableData?.length ? "block" : "none",
              }}
              disabled={!selectedIcpId || !tableData?.length}
              onClick={handleEnrich}
            >
              Enrich
            </Buttons>
            <Input
              placeholder="Search..."
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              size="large"
              style={{ width: "250px", height: "39.5px" }}
              prefix={<SearchOutlined />}
            />
            <Select
              className="selects"
              placeholder="Select ICP"
              options={icpDropdownData}
              onChange={(value) => setSelectedIcpId(value)}
              size="large"
              style={{ width: "250px", margin: 0 }}
            />
            <Popover
              content={popoverContent}
              title="Table Properties"
              trigger="click"
              className="pop"
            >
              <Button icon={<TableOutlined style={{ fontSize: "20px" }} />} />
            </Popover>
          </div>
        </div>
        <div className="ads_section">
          <div className="ads_section_table">
            {isLoading ? (
              <Loader />
            ) : (
              <Table
                size="small"
                bordered
                columns={filteredColumns}
                dataSource={tableData.map((item, index) => ({
                  ...item,
                  companyID: item.companyID || `fallback-key-${index}`,
                }))}
                components={{
                  header: {
                    cell: ResizableTitle,
                  },
                }}
                pagination={{
                  current: pageIndex,
                  pageSize,
                  total: totalCount,
                  showSizeChanger: true,
                  pageSizeOptions: getPageSizeOptions(),
                  onChange: (page, size) => {
                    setPageIndex(page);
                    setPageSize(size);
                  },
                }}
                scroll={{
                  x: 1500,
                  y: tableHeight,
                }}
                rowKey="companyID"
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        width="80vh"
        footer={[
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <div>
          <CSVLink
            data={
              apiResponseData?.length
                ? apiResponseData.map((profile) => ({
                    MasterID: profile.MasterID,
                    FullName: profile.FullName,
                    Title: profile.Title,
                    Company: profile.CompanyName,
                    "Web Address": profile.WebAddress,
                    "Email Address": profile.EmailAddress,
                    "Company Phone": profile.CompanyPhone,
                    industry: profile.Industry,
                    "Linkedin URL": profile.LinkedInURL,
                    TotalCount: profile.TotalCount,
                    PerformedAction: profile.PerformedAction,
                  }))
                : [
                    {
                      MasterID: null,
                      FullName: null,
                      Title: null,
                      Company: null,
                      "Web Address": null,
                      "Email Address": null,
                      "Company Phone": null,
                      industry: null,
                      "Linkedin URL": null,
                      TotalCount: null,
                      PerformedAction: null,
                    },
                  ]
            }
            headers={[
              { label: "MasterID", key: "MasterID" },
              { label: "FullName", key: "FullName" },
              { label: "Title", key: "Title" },
              { label: "Company", key: "Company" },
              { label: "Web Address", key: "Web Address" },
              { label: "Email Address", key: "Email Address" },
              { label: "Company Phone", key: "Company Phone" },
              { label: "industry", key: "industry" },
              { label: "Linkedin URL", key: "Linkedin URL" },
              { label: "TotalCount", key: "TotalCount" },
              { label: "PerformedAction", key: "PerformedAction" },
            ]}
            filename={"profiles.csv"}
            target="_blank"
          >
            <Button type="primary" style={{ marginBottom: 10 }}>
              Download CSV
            </Button>
          </CSVLink>
        </div>
        <Table
          columns={columns2}
          dataSource={apiResponseData}
          rowKey={(record) => record?.FullName}
          pagination={{
            current: pageIndexModal,
            total: totalPagesModal * 10,
            pageSize: 10,
            onChange: handlePageChangeModal,
          }}
        />
      </Modal>
    </>
  );
};

export default GoogleAds;
